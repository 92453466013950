.nav-tabs {
  border: 1px solid #dee2e6;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs > .nav-item {
  flex-grow: 1;
  flex-basis: 0;
}

.nav-tabs > .nav-item > .nav-link {
  width: 100%;
  border: none;
  font-size: 18px;
  padding: 1.5rem 1rem;
  margin-bottom: 0;
}

.nav-tabs > .nav-item > .nav-link.active {
  background-color: rgb(2, 79, 16);
  color: white;
  font-weight: bold;
}

.tab-content {
  padding: 1.5rem;
  border: 1px solid #dee2e6;
  border-top: none;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.thumb {
  padding: 4px;
  width: 100px;
  height: 100px;
  border-radius: 0.3rem;
  display: inline-flex;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.thumb > img {
  width: 100%;
  height: 100%;
  display: block;
}

.thumb > button {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  font-weight: 900;
  color: var(--bs-danger);
  background-color: white;
}

.question {
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  padding: 1rem;
  cursor: default;
}

.question:not(:last-child) {
  margin-bottom: 1rem;
}

.question.selected {
  border-color: var(--bs-primary);
}

.question > .footer {
  display: flex;
}

.question > .footer > p,
.view-questions-data {
  color: #6c757d;
  font-size: 0.875em;
  margin: 0.5rem 0 0;
}

.action {
  font-weight: bold;
  font-size: 24px;
  cursor: default;
  opacity: 0.6;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.action:not(:last-child) {
  margin-right: 0.3rem;
}

.action:hover {
  opacity: 1;
}

.action.delete {
  color: var(--bs-danger);
}

.action.up {
  color: var(--bs-success);
}

.action.down {
  color: var(--bs-info);
}

.action.check {
  color: var(--bs-warning);
}

.action.edit {
  color: var(--bs-primary);
}

.action.back {
  color: var(--bs-secondary);
}
