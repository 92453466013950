.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  position: sticky !important;
  z-index: 1;
  background: white;
  z-index: 1;
}
.previous-years-actions {
  column-gap: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .actions-on-right {
    display: flex;
    align-items: center;
    gap: 25px;
  }

  .tag {
    padding: 6px 20px;
    width: fit-content;
    display: flex;
    align-items: center;
    background: rgb(198, 215, 201);
    justify-content: center;
    height: auto;
    border-radius: 0.5rem;
  }

  .tag-publish {
    opacity: 0.7;
    display: flex;
    align-items: center;
    background: #b2b2b2;
    justify-content: center;
    width: fit-content;
  }

  .show {
    background: #024f10;
    color: white;
    text-align: center;
    padding: 6px 14px;
    border-radius: 5px;
    cursor: pointer;
    width: 70px;
  }

  .hide {
    text-align: center;
    background: #c6d7c9;
    width: 70px;
    color: white;
    padding: 6px 14px;
    border-radius: 5px;
    cursor: pointer;
  }

  .publish-unpublish {
    svg {
      font-size: 2em;
      cursor: pointer;
    }
  }
}

.subjects-by-depart {
  display: flex;
  align-items: center;
  margin-top: 0.1rem;
  justify-content: space-between;
  .tag {
    padding: 6px 20px;
  }
  .lock {
    cursor: pointer;
  }
}

.year-range {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  text-align: center;
 
  div {
    display: flex;
    align-items: center;
    gap: 5px;

    .form-group {
      margin-bottom: 0 !important;
    }
  }
}
